<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-4 mt-1 white--text"
        color="xbColor"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-plus</v-icon>
        Vendedor
      </v-btn>
    </template>

    <v-card>
      <v-card-text class="py-7">
        <v-combobox
          v-model="selectedStaffVendedor"
          label="Vendedor"
          :items="staffVendedores"
          dense
          item-text="nome"
          item-value="id"
          return-object
          hide-details
          clearable
        ></v-combobox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          :disabled="selectedStaffVendedor ? false : true"
          @click="updateVendedor"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { allStaffVendedores } from "@/api/vendedores.js";
import { putUsuarioGeral } from "@/api/admin/usuarios.js";

export default {
  name: "DialogAddVendedor",

  props: {
    user_id: {
      type: [Number, String],
      require: true,
    },
  },

  data() {
    return {
      loading: false,
      dialog: false,
      staffVendedores: [],
      selectedStaffVendedor: null,
    };
  },

  methods: {
    async getUsuarioStaffs() {
      this.loading = true;
      await allStaffVendedores()
        .then((response) => {
          this.staffVendedores = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateVendedor() {
      putUsuarioGeral(this.user_id, {
        vendedor_id: this.selectedStaffVendedor.id,
      })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Atualizado com sucesso");
            this.$emit("add-vendedor", this.selectedStaffVendedor);
            this.$emit("reload-leads");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getUsuarioStaffs();
  },
};
</script>

<style></style>
